<template>
  <div v-if="dataLoaded">
    <v-container class="pa-0">
      <app-view-toolbar
        :title="title"
        :showBackButton="true"
        :buttons="toolbarButtons"
        @onButton="onToolbarButton">
      </app-view-toolbar>
    </v-container>
    <v-container class="py-2 px-6">
      <select-list
        selectLabel="Välj föreställning"
        selectPlaceholder="Lägg till föreställning..."
        selectOptionValue="id"
        selectOptionText="name"
        :selectOptions="listData.items"
        :selectLimit="1"
        :invisibleOptions="listData.invisibleOptions"
        :listHeaders="listData.headers"
        v-model="selectedSessions">
      </select-list>
    </v-container>
  </div>
</template>
  
<script>
import { onMounted, ref, reactive } from '@vue/composition-api';
import useCore from '../../../helpers/core';
import ViewToolbar from '../../../components/ViewToolbar.vue';
import SelectList from '../../../components/SelectList.vue';

export default {
  name: 'movieSplash',
  components: {
    'app-view-toolbar': ViewToolbar,
    'select-list': SelectList
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const { callApi, showMessage, tellKobra } = useCore();

    const isNew = !props.id;
    const dataLoaded = ref(false);
    const showDeleteConfirm = ref(false);

    const title = 'Enskild föreställning';
    const listData = reactive({
      headers: [
        { text: "Namn", value: "name" },
      ],
      items: [],
      invisibleOptions: [],
    });

    const selectedSessions = ref([]);

    // Actions
    const onSave = () => {
      const data = selectedSessions.value.map((id, index) => ({ id, rank: index + 1 }));
      callApi({ method: 'post', path: `/cms/folketshus/sessions/${props.id}`, data })
          .then(() => {
            showMessage(`Föreställning har sparats`);
            tellKobra('close');
          })
          .catch((err) => {
            return err;
          });
    };
    const onDelete = () => {
      showDeleteConfirm.value = true;
    }
    const onDeleteConfirm = () => {
    };

    // Toolbar actions
    const toolbarButtons = [{ icon: 'check', name: 'Spara', fn: onSave }];
    const onToolbarButton = (button) => button.fn();

    // Lifecycle-methods
    const doOnMounted = () => {
      // Get selectable movies
      callApi({ method: 'get', path: `/cms/folketshus/sessions` })
        .then((res) => {
          dataLoaded.value = true;
          listData.items = res.map(({ id, name })=> ({ id, name }));
        })
        .catch((err) => {
          return err;
        });
      
      // Get already selected movies
      if (!isNew) {
        callApi({ method: 'get', path: `/cms/folketshus/sessions/${props.id}` })
          .then((res) => {
            listData.invisibleOptions = res.map(({ id, name }) => ({ id, name }));
            selectedSessions.value = res.map((e) => e.id);
          })
          .catch((err) => {
            return err;
          });
      }
    };
    onMounted(doOnMounted);

    return {
      title,
      dataLoaded,
      toolbarButtons,
      onToolbarButton,
      listData,
      selectedSessions,
      
      showDeleteConfirm,
      onDeleteConfirm,
      onDelete,
    };
  },
};
</script>

<style>
</style>
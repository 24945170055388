
<template>
  <div>
    <v-container class="py-2 px-6">
      <v-select
        :disabled="disabled"
        :label="selectLabel"
        :placeholder="selectPlaceholder"
        :items="filteredOptions"
        :item-value="selectOptionValue"
        :item-text="selectOptionText"
        @change="onUpdateValue"
        dense
        outlined
        persistent-placeholder
        background-color="white">
      </v-select>

      <v-data-table
        :headers="[...listHeaders, {}]"
        :items="selectedValues"
        :no-data-text="listNoDataText"
        :no-results-text="listNoResultsText"
        :page.sync="listPage"
        :items-per-page="listItemsPerPage"
        @page-count="listPageCount = $event"
        hide-default-footer
        disable-sort
        class="click-table"
        locale="sv-SE">
        <template #body="props">
          <draggable
            tag="tbody"
            :list="selectedValues"
            @end="onSetRank">
            <template>
              <tr v-for="(item, index) in props.items" :key="index">
                <td v-for="(header, headerIndex) in listHeaders" :key="headerIndex">
                  {{item[header.value]}}
                </td>
                <td class="text-right">
                  <v-btn
                    text
                    color="red"
                    type="button"
                    @click="onUpdateValue(item[selectOptionValue])"
                    small>
                    <v-icon left>mdi-trash-can-outline</v-icon>
                    Ta bort
                  </v-btn>
                </td>
              </tr>
            </template>
          </draggable>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>
  
<script>
  import { computed, ref } from '@vue/composition-api';
  import draggable from 'vuedraggable';
  
  export default {
    name: 'selectList',
    model: {
      prop: 'value',
      event: 'change',
    },
    props: {
      selectLabel: { type: String, default: 'Lägg till i lista', required: false },
      selectPlaceholder: { type: String, default: 'Välj...', required: false },
      selectOptions: { type: Array, required: true },
      invisibleOptions: { type: Array, required: false },
      selectOptionValue: { type: [String, Number], required: true },
      selectOptionText: { type: String, required: true },
      selectLimit: { type: Number, required: false },

      listNoDataText: { type: String, default: 'Här var det tomt', required: false },
      listNoResultsText: { type: String, default: 'Din sökning gav inga resultat', required: false },
      listHeaders: { type: Array, required: true },
      value: { type: Array, required: false },
    },
    components: {
      draggable,
    },
    setup(props, context) {
      const disabled = ref(true);

      // Remove selected options from select-list
      const filteredOptions = computed(() => {
        return props.selectOptions.filter((opt) => {
          return !props.value.includes(opt[props.selectOptionValue]);
        });
      });

      // Get the complete item for selected values
      const selectedValues = computed(() => {
        const ret = props.value.map((val) => {
          let index = props.selectOptions.findIndex((e) => e[props.selectOptionValue] === val);
          if (index === -1 && !!props.invisibleOptions) {
            index = props.invisibleOptions.findIndex((e) => e[props.selectOptionValue] === val);
            return props.invisibleOptions[index];
          }
          return props.selectOptions[index];
        }, []);
        disabled.value = props.selectLimit && props.selectLimit <= ret.length;
        return ret;
      });

      // Notify parent of changes occurring in component, Add/Remove
      const onUpdateValue = (e) => {
        let newValue;
        if (props.value.includes(e)) {
          newValue = props.value.filter(c => c !== e);
        } else {
          newValue = [...props.value, e];
        }
        context.emit('change', newValue);
      };

      // Sort array 
      const onSetRank = () => {
        let newValue = selectedValues.value.map((e) => e[props.selectOptionValue]);
        context.emit('change', newValue);
      };
  
      return {
        disabled,
        filteredOptions,
        selectedValues,
        onUpdateValue,
        onSetRank,
        listPage: 1,
        listPageCount: 0,
        listItemsPerPage: 100,
      };
    },
  };
</script>

<style>

</style>
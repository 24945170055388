<template>
  <div v-if="dataLoaded">
    <x-movie-list v-if="emailMessageAreaType === 'zFhMovies'" :id="id"></x-movie-list>
    <x-movie-splash v-else-if="emailMessageAreaType === 'zFhSplash'" :id="id"></x-movie-splash>
  </div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api';
import useCore from '../../helpers/core';
import MovieList from './folketshus/MovieList.vue';
import MovieSplash from './folketshus/MovieSplash.vue';

export default {
  name: 'emailMessageObject',
  components: {
    'x-movie-list': MovieList,
    'x-movie-splash': MovieSplash,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const { callApi } = useCore();

    const dataLoaded = ref(false);
    const emailMessageAreaType = ref();

    const doOnMounted = () => {
      callApi({ method: 'get', path: `/cms/mailing/email-message-object/${props.id}/type` })
        .then((res) => {
          dataLoaded.value = true;
          emailMessageAreaType.value = res;
        })
        .catch((err) => {
          return err;
        });
    };
    onMounted(doOnMounted);

    return {
      dataLoaded,
      emailMessageAreaType,
    };
  }
};
</script>

<style>
</style>